import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup, createAddYouTubePopup } from '../actions/popup';
import { createFetchIdentity, createFeedback, createStartTour } from '../actions';

import { BASE_ZINDEX } from '../popup-factory';

class TourMilestonePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show_feedback_form: false,
      feedback: props.step_feedback ? props.step_feedback.feedback : '',
      show_support_form: false,
      next: props.next ? props.next : 0,
      max_present_index: 2,
      max_sales_order_index: 2,
      max_purchase_order_index: 1,
      end: false,
      show_to_feedback_button: false
    };

    _.bindAll(this, ['toggleFeedbackForm', 'giveFeedback', 'renderTitle', 'renderDescription', 'goBack', 'goNext', 'renderNextBtn', 'finishTour', 'renderToFeedbackBtn']);
  }

  UNSAFE_componentWillReceiveProps(np) {
    if(np.step_feedback) {
      this.setState({ feedback: np.step_feedback.feedback });
    }
  }

  componentDidMount() {
    this.props.onCreateFetchIdentity();
  }

  toggleFeedbackForm() {
    this.setState({ show_feedback_form: !this.state.show_feedback_form, show_to_feedback_button: false });
  }

  goBack() {
    if(this.state.next != 0 && !this.state.show_feedback_form && !this.state.show_support_form) {
      this.setState({ next: this.state.next - 1 });
    }
    this.setState({ show_feedback_form: false, show_support_form: false });
  }

  goNext() {
    this.setState({ next: this.state.next + 1 });
  }

  giveFeedback(value) {
    this.setState({ feedback: value });
    /* let _this = this;
    if(value == 'good' || value == 'great') {
      if(this.props.milestone !== 'create-purchase-order') {
        setTimeout(function(){
          _this.props.onClosePopup();
        }, 1500);
      }else{
        this.setState({ end: true });
      }
    }else if(value == 'confusing') {
      setTimeout(function(){
        _this.setState({ show_feedback_form: false, show_support_form: false});
      }.bind(_this), 1500);
    } */
    this.props.onCreateFeedback(this.props.tour_steps[this.props.milestone], value, this.props.milestone);
  }

  finishTour() {
    this.setState({ show_feedback_form: false, end: true });
  }

  renderNextBtn(milestone, next) {
    if(milestone === 'create-presentation' && next == 0) {
      return <button className="button tour-button" onClick={this.goNext}>Next</button>;
    }else if(milestone === 'create-presentation' && next == 1) {
      return <button className="button tour-button" onClick={this.goNext}>Next</button>;
    }else if(milestone === 'create-sales-order' && next == 0) {
      return <button className="button tour-button" onClick={this.goNext}>Next</button>;
    }else if(milestone === 'create-sales-order' && next == 1) {
      return <button className="button tour-button" onClick={this.goNext}>Next</button>;
    }else if(milestone === 'create-purchase-order' && next == 0) {
      return <button className="button tour-button" onClick={this.goNext}>Next</button>;
    }
  }

  renderToFeedbackBtn() {
    this.setState({ show_to_feedback_button: true });
  }

  renderTitle(milestone, next) {
    switch(milestone) {
      case 'create-client':
        return <h3 id="modalTitle" className="tour-color-red">Creating a Client</h3>;
        break;
      case 'create-project':
        return <h3 id="modalTitle" className="tour-color-red">Creating a Project</h3>;
        break;
      case 'create-presentation':
        if(next == 0) {
          return <h3 id="modalTitle" className="tour-color-red">Creating a Presentation</h3>;
        }else if(next == 1) {
          return <h3 id="modalTitle" className="tour-color-red">Editing Products</h3>;
        }else if(next == 2) {
          return <h3 id="modalTitle" className="tour-color-red">Sending a Presentation</h3>;
        }
        break;
      case 'create-sales-order':
        if(next == 0) {
          return <h3 id="modalTitle" className="tour-color-red">Converting to a Sales Order</h3>;
        }else if(next == 1) {
          return <h3 id="modalTitle" className="tour-color-red">Editing Products on a Sales Order</h3>;
        }else if(next == 2) {
          return <h3 id="modalTitle" className="tour-color-red">Sending Out a Sales Order</h3>;
        }
        break;
      case 'create-purchase-order':
        if(next == 0) {
          return <h3 id="modalTitle" className="tour-color-red">Completing the Shipping Details</h3>;
        }else if (next == 1) {
          return <h3 id="modalTitle" className="tour-color-red">Creating Purchase Orders</h3>;
        }
        break;
    }
  }

  renderDescription(milestone, next) {
    switch(milestone) {
      case 'create-client':
        return  <div className="row">
                  <p>The first step in exploring commonsku is to create a client. Clients are set up at the company level, the individual buyers you work with are known as Contacts in commonsku</p>
                  <p>You can watch this quick video to learn how to create your own client.</p>
                </div>;
        break;
      case 'create-project':
        return  <div className="row">
                  <p>Next step: creating a Project. A project is where you start when you want to  create a Presentation, Estimate, or Sales Order that you will send to your client.</p>
                  <p>On the top right of your Client page, click the Create New button and give the project a name. The name should describe the project and be easily recognizable and searchable. For example: Notebooks for Vail 2017 Conference.</p>
                  <p>Next, select a Contact and an Event Type. The Event Type describes the purpose of the project and helps with reporting.</p>
                </div>;
        break;
      case 'create-presentation':
        if(next == 0) {
          return  <div className="row">
                    <p>Once you have begun the Project, you can create a Presentation. On the top right of the Project page you will see an Action button. Click Action, and select Create Presentation.</p>
                    <p>On the Presentation, you will see the Products section with a blue Add button. You can either use test products we have created in your own database (just click Search), or you can connect your preferred database.</p>
                    <p>The video below walks you through how you can connect SAGE, ESP or Distributor Central</p>
                    <p>Try adding some products and then in the next screen we'll show you how to edit those products.</p>
                  </div>;
        }else if(next == 1) {
          return  <div className="row">
                    <p>After you have added as many products as you want to the Presentation, you can now customize each product for this Presentation.</p>
                    <p>Watch this quick video to see all the ways you can customize products.</p>
                    <p>Go ahead and edit the products and then we'll tell you how to send the presentation.</p>
                  </div>;
        }else if(next == 2) {
          return  <div className="row">
                    <p>Want to see what that beautiful Presentation looks like? Send it to yourself or email it to your client! Click the Action button on the top right, you can both Preview the Presentation as well as Email it.</p>
                    <p>In the middle of the Presentation screen, you will see an area called Presentation Styles. Experiment with the different styles to see what they look like.</p>
                  </div>;
        }
        break;
      case 'create-sales-order':
        if(next == 0) {
          return <div className="row">
                  <p>That beautiful presentation is going to make your client want to place an order!  The next step is to convert the Presentation to a Sales Order. The Sales Order will create a confirmation to your client and provides the basis of the Purchase Orders.</p>
                  <p>Back to our favorite Action button on the top right hand side, select Create Sales Order. Indicate the quantity of the products you want to convert and then click Convert to Sales Order.</p>
                  <p>Go ahead and do that then come back to learn about editing products on the Sales Order.</p>
                </div>;
        }else if(next == 1) {
          return <div className="row">
                  <p>After the Sales Order is created, you can now get into the details of the product - size, color, and decoration.</p>
                  <p>Try adding an art file to your sales order, then come back and we'll tell you how to send it to your client for approval.</p>
                  <p>For a quick how-to, watch the video below.</p>
                </div>;
        }else if(next == 2) {
          return <div className="row">
                  <p>Once you have all the product and decoration details complete, you can send the Sales Order out for approval. Email it to yourself so you can see what the client experiences with the digital approval process!</p>
                  <p>Navigate to our favorite pink Action button, select Email to Client and you can then determine who you are sending it to and what you want to say in the email.</p>
                </div>;
        }
        break;
      case 'create-purchase-order':
        if(next == 0) {
          return  <div className="row">
                    <p>The first step to creating Purchase Orders is determine where the products will be produced and when they should be shipped. Do they need to go do to a separate Decorator or is the Vendor decorating in-house and shipping directly to the client?</p>
                    <p>Watch this quick video to see how to complete this information in the Shipping area, then go ahead and complete the shipping information</p>
                  </div>;
        }else if(next == 1) {
          return  <div className="row">
                    <p>With the shipping information complete, you are now ready to create your Purchase Orders. Simply go to the Purchase Orders tab on the left in your project and click Generate Purchase Orders.</p>
                    <p>This will create all the corresponding Purchase Orders for your job.  To preview the Purchase Orders click on the PO number and it will launch the Purchase Order in a separate tab for you to review.</p>
                    <p>You can also email the Purchase Order by clicking the Email button to the right and it will open the email window to send the PO, along with the artwork, a Shipping Label and Packing Slip.</p>
                  </div>;
        }
        break;
    }
  }

  render() {
    const {
      index,
      milestone,
      next,
      latest_trial_job_number,
      tour_steps,
      step_feedback,
      user_id,
      onClosePopup,
      onCreateAddYouTubePopup,
      onCreateFetchIdentity,
      onCreateFeedback,
      onCreateStartTour
    } = this.props;
    const active = {
      color: 'yellow',
      fontSize: '8em'
    };
    const inactive = {
      color: 'gray',
      fontSize: '8em'
    };
    const video_id = milestone === 'create-client' ? 'trial_client.mp4' :
                     milestone === 'create-presentation' && this.state.next == 0 ? 'create-presentation-0' :
                     milestone === 'create-presentation' && this.state.next == 1 ? 'trial_presentation_product.mp4' :
                     milestone === 'create-sales-order' && this.state.next == 1 ? 'trial_sales_order.mp4' :
                     milestone === 'create-purchase-order' && this.state.next == 0 ? 'trial_shipping.mp4' :
                     null;
    const background_image = milestone === 'create-client' ? '/images/create_client.jpg' :
                             milestone === 'create-project' ? '/images/create_project.jpg' :
                             milestone === 'create-presentation' && this.state.next == 0 ? '/images/create_presentation.jpg' :
                             milestone === 'create-presentation' && this.state.next == 1 ? '/images/edit_present_product.jpg' :
                             milestone === 'create-presentation' && this.state.next == 2 ? '/images/email_presentation.jpg' :
                             milestone === 'create-sales-order' && this.state.next == 0 ? '/images/create_sales_order.jpg' :
                             milestone === 'create-sales-order' && this.state.next == 1 ? '/images/edit_product_sales_order.jpg' :
                             milestone === 'create-sales-order' && this.state.next == 2 ? '/images/email_sales_order.jpg' :
                             milestone === 'create-purchase-order' && this.state.next == 0 ? '/images/sales_order_shipping.jpg' :
                             milestone === 'create-purchase-order' && this.state.next == 1 ? '/images/purchase_order_2.jpg' :
                             null;
    const opacity_low = {
      opacity: 0.5
    };

    return (
      <div className="reveal large tour-text tour" style={{display: 'block', zIndex: BASE_ZINDEX + index, padding: '50px'}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <div className="medium-7 column" style={{paddingLeft : '0px'}}>
              {!this.state.show_feedback_form  && !this.state.show_support_form && !this.state.end ?
                this.renderTitle(milestone, this.state.next)
              : null}
            </div>
          </div>
        </div>
        {milestone == 'welcome' ?
          <div className="row popup-content">
            <div className="row tour-color-red">
              <h3>Welcome to your free trial of commonsku!</h3>
            </div>
            <div className="row">
              <p>This step-by-step guide will walk you through the most popular features, like creating a Client, a Presentation, a Sales Order, and Purchase Orders.  Each page will provide step-by-step instructions and will link you to commonsku to try it out!</p>
              <p>The links will open a new browser tab, so you can always refer back to this guide as you go along.</p>
            </div>
            <div className="row">
              <i>If you close this tab by accident, you can get back to it by clicking the <a className="button tour" style={{float: 'none'}}>Tour</a> on the top of your page.</i>
            </div>
            <button className="button alt tour-button" style={{marginTop: '20px'}} onClick={e => {e.preventDefault(); onCreateStartTour(user_id);}}>Get Started &gt; </button>
          </div>
        : milestone == 'create-purchase-order' && this.state.end ?
          <div className="row popup-content">
            <div className="row tour-color-red">
              <h3>What Next?</h3>
            </div>
            <div className="row">
              <p>Most people find that by the time they have created Purchase Orders, they are comfortable with the order management process.</p>
            </div>
            <div className="row">
              <p>But, if you’re still excited about learning more, we don’t want to stop you!  For additional videos and tutorials, check out <a target="_blank" href="/university.php">commonsku University</a> where you can explore many other areas of the platform.</p>
            </div>
            <div className="row">
              <p>We look forward to the call we have booked to hear how your trial has gone!</p>
            </div>
            <a className="button tour-button" target="_blank" href="/university.php">Go to commonsku University</a>
            <a href="/home.php" className="button alt tour-button" style={{marginRight: '20px'}}>Exit Tour</a>
          </div>
        :
          <div className="row popup-content">
            {!this.state.show_feedback_form && !this.state.show_support_form ?
              <div className="row">
                <div className="medium-7 column">
                  {this.renderDescription(milestone, this.state.next)}
                  <div className="row">
                    {video_id ? <button className="button tour-button watch-video" style={{float: 'left'}} onClick={e => {e.preventDefault(); onCreateAddYouTubePopup(video_id, milestone, this.state.next); onClosePopup(-1);}}><i className="fi-play large"></i>&nbsp;&nbsp;Watch Video</button> : null}
                    {this.state.next != 0 && !this.state.show_feedback_form && !this.state.show_support_form && !this.state.end?
                      <button className="button tour-button tour-back" onClick={this.goBack}>Previous</button>
                    : null}
                    {milestone == 'create-client' ?
                      <a href="/clients.php" target="_blank" className="button tour-button" onClick={this.renderToFeedbackBtn}>{!step_feedback ? 'Create your first client' : 'View your clients'}</a>
                    : milestone == 'create-project' ?
                      <a href={latest_trial_job_number ? `/project/${latest_trial_job_number}` : '/new_project.php'} target="_blank" className="button tour-button" onClick={this.renderToFeedbackBtn}>{latest_trial_job_number ? 'Go to your Project' : 'Create your first project'}</a>
                    : milestone == 'create-presentation' && this.state.next == this.state.max_present_index ?
                      <a href={latest_trial_job_number ? `/project/${latest_trial_job_number}` : '/new_project.php'} target="_blank" className="button tour-button" onClick={this.renderToFeedbackBtn}>{latest_trial_job_number ? 'Go to your Project' : 'Create your first project'}</a>
                    : milestone == 'create-sales-order' && this.state.next == this.state.max_sales_order_index ?
                      <a href={latest_trial_job_number ? `/project/${latest_trial_job_number}` : '/new_project.php'} target="_blank" className="button tour-button" onClick={this.renderToFeedbackBtn}>{latest_trial_job_number ? 'Go to your Project' : 'Create your first project'}</a>
                    : milestone == 'create-purchase-order' && this.state.next == 0 ?
                      <a href={latest_trial_job_number ? `/project/${latest_trial_job_number}/shipping` : '/new_project.php'} target="_blank" className="button tour-button">{latest_trial_job_number ? 'Go to Shipping Details' : 'Create your first project'}</a>
                    : milestone == 'create-purchase-order' && this.state.next == this.state.max_purchase_order_index ?
                      <a href={latest_trial_job_number ? `/project/${latest_trial_job_number}/production` : '/new_project.php'} target="_blank" className="button tour-button" onClick={this.renderToFeedbackBtn}>{latest_trial_job_number ? 'Go to Purchase Orders' : 'Create your first project'}</a>
                    : null}
                    {!this.state.show_feedback_form && !this.state.show_support_form && !this.state.end ?
                      this.renderNextBtn(milestone, this.state.next)
                    : null}
                    {this.state.show_to_feedback_button && !this.state.show_feedback_form && !this.state.show_support_form && !this.state.end ?
                      <button className="button tour-button" onClick={this.toggleFeedbackForm}>Next</button>
                    : null}
                  </div>
                </div>
                <div className="medium-5 column">
                  <img src={background_image} className="tour-background" />
                </div>
              </div>
            : this.state.show_support_form && this.state.feedback == 'confusing' ?
              <div className="row" style={{textAlign: 'center'}}>
                <h3 className="tour-color-red">Need help creating your first client ?</h3>
                <h5>Call us toll free at (1877)-209-6496</h5>
              </div>
            : this.state.show_feedback_form && !this.state.end ?
              <div>
                <div className="row">
                  <h3 className="tour-color-red" style={{textAlign: 'center'}}>How did that go?</h3>
                </div>
                <div className="row" style={{textAlign: 'center'}}>
                  <div className="medium-4 column feedback-pointer" onClick={e => {e.preventDefault(); this.giveFeedback('confusing');}}>
                    {this.state.feedback === 'confusing' ? <img src="images/confusing-selected.jpg" className="feedback-selected" /> : <img src="images/confusing.jpg" className="feedback-unselected" />}
                    <h5 style={this.state.feedback !== 'confusing' ? opacity_low : null}>Confusing</h5>
                  </div>
                  <div className="medium-4 column feedback-pointer" onClick={e => {e.preventDefault(); this.giveFeedback('good');}}>
                    {this.state.feedback === 'good' ? <img src="images/good-selected.jpg" className="feedback-selected" /> : <img src="images/good.jpg" className="feedback-unselected" />}
                    <h5 style={this.state.feedback !== 'good' ? opacity_low : null}>Good</h5>
                  </div>
                  <div className="medium-4 column feedback-pointer" style={{float: 'left'}} onClick={e => {e.preventDefault(); this.giveFeedback('great');}}>
                    {this.state.feedback === 'great' ? <img src="images/great-selected.jpg" className="feedback-selected" /> : <img src="images/great.jpg" className="feedback-unselected" />}
                    <h5 style={this.state.feedback !== 'great' ? opacity_low : null}>Great</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="medium-4 columns">
                    &nbsp;
                  </div>
                  <div className="medium-4 columns">
                    <button className="button tour-button tour-back" onClick={this.goBack}>Previous</button>
                    {milestone !== 'create-purchase-order' && this.state.next != 1 ?
                      <button className="button tour-button" style={{float: 'right'}} onClick={this.props.onClosePopup}>Next</button>
                    : milestone === 'create-purchase-order' && this.state.next == 1 ?
                      <button className="button tour-button" style={{float: 'right'}} onClick={this.finishTour}>Next</button>
                    : null}
                  </div>
                  <div className="medium-4 columns">
                    &nbsp;
                  </div>
                </div>
              </div>
            : null}
          </div>
        }
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let step_feedback = Object.values(state.feedbacks).filter(f => f.tour_step_id == state.tour_steps[ownProps.milestone]) ? Object.values(state.feedbacks).filter(f => f.tour_step_id == state.tour_steps[ownProps.milestone])[0] : null;
  let user_id = state.identity.user_id;

  return {
    latest_trial_job_number: state.identity.latest_trial_job_number,
    tour_steps: state.tour_steps,
    step_feedback,
    user_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: (index) => {
      dispatch(closePopup(index));
    },
    onCreateAddYouTubePopup: (video_id, milestone, next) => {
      dispatch(createAddYouTubePopup(video_id, milestone, next));
    },
    onCreateFetchIdentity: () => {
      dispatch(createFetchIdentity());
    },
    onCreateFeedback: (tour_step_id, feedback, tour_step_key) => {
      dispatch(createFeedback(tour_step_id, feedback, tour_step_key));
    },
    onCreateStartTour: (user_id) => {
      console.log('1');
      dispatch(createStartTour(user_id));
      dispatch(closePopup());
    }
  };
};

const ConnectedTourMilestonePopup = connect(mapStateToProps, mapDispatchToProps)(TourMilestonePopup);
export default ConnectedTourMilestonePopup;

